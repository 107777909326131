<template>
  <v-menu :close-on-content-click="true">
    <template v-slot:activator="{ on: activatorOn }">
      <slot name="activator" :current="currentPrinter" :on="activatorOn">
        <v-btn
          class="printer-select__activator"
          text
          color="accent"
          v-on="activatorOn"
          @click="refreshItems"
          v-text="currentPrinter.displayName || 'Select a printer'"
        />
      </slot>
    </template>
    <v-list :key="renderKey">
      <template v-if="items.length === 0">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>No printers available</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-for="printer in items" v-else>
        <v-list-item
          :key="JSON.stringify(printer)"
          :color="(isSelected(printer) && `primary`) || ``"
          @click="setPrinter(printer)"
        >
          <v-list-item-content>
            <v-list-item-title v-text="printer.displayName" />
          </v-list-item-content>
          <v-list-item-action v-if="isSelected(printer)">
            <v-icon color="primary" v-text="`$vuetify.icons.check`" />
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { hasSnackbarAccess } from "@/mixins/ui";

export default {
  name: "PrinterSelect",
  mixins: [hasSnackbarAccess],
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    renderKey: 0,
    items: [],
    dialog: false,
  }),
  computed: {
    currentPrinter() {
      return this.$raiPos.printer();
    },
  },
  created() {
    this.refreshItems();
  },
  methods: {
    refreshItems() {
      this.items =
        (this.$raiPos &&
          this.$raiPos.availablePosDevices &&
          this.$raiPos.availablePosDevices()) ||
        [];

      this.renderKey++;
    },
    setPrinter(printer) {
      this.$raiPos.setPrinter(printer);
      this.$emit("input", false);
      this.showSnackbar({
        text: `Printer changed to ${this.$raiPos.printerDisplayName()}`,
      });
      this.renderKey++;
    },
    isSelected(printer) {
      return this.$raiPos.printerName() === printer.name;
    },
  },
};
</script>
